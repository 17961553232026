.top-section{
  padding-top: 100px;
  overflow: hidden;
  position: relative;
  height: 100vh;
  background-position: center center;
  background-size: cover;
  @include laptop{
    background: #000 url("../img/decor/top-bg.jpg") no-repeat;
    background-position: bottom center;
    background-size: cover;
  }
}
.top-slider{
  padding-top: 80px;
  @include centered(both);
  display: flex;
  align-items: center;
  justify-content: center;
  @include laptop{
    display: none;
  }
}
.top-section__content{

  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 60%;
  //height: 400px;
  text-align: left;
  @include laptop{
    width: 90%;
  }
  @include tablet{
    width: 90%;
  }
}
.top-content__text{
  color: #fff;
  font-size: 25px;
  text-align: left;
  width: 70%;
  @include tablet{
    font-size: 20px;
  }
  @include phone-hor-orientation{
    font-size: 16px;
    width: 95%;
  }
}


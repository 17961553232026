.contact-form__wrapper{
  position: absolute;
  top: 150px;
  right: 17%;
  z-index: 1000;
  padding: 20px;
  border: 2px solid #fff;
  width: 250px;
  @include desktop{
    right: 50px;
  }
  @include laptop{
    right: 5%;
  }
  @include tablet{
    top: 150px;
  }
  @include phone-hor-orientation{
    display: none;
  }
}
.contact-form{
  text-align: center;
}
.contact-form__title{
  color: #fff;
  font-size: 18px;
  margin-bottom: 10px;
  text-align: center;
}
.input-group{
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.input{
  margin-bottom: 5px;
  background-color: #b3b6c2;
  outline: none;
  font-size: 12px;
  color: #fff;
  padding: 10px;
  border: 1px solid transparent;
  &:last-child{
    margin-bottom: 0;
  }
  &:focus{
    border:1px solid $blue;
  }
}
.input::-webkit-input-placeholder       {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input::-moz-placeholder                {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:-moz-placeholder                 {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:-ms-input-placeholder            {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:focus::-webkit-input-placeholder {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:focus::-moz-placeholder          {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:focus:-moz-placeholder           {color:#fff; opacity: 1; transition: opacity 0.3s ease;}
.input:focus:-ms-input-placeholder      {color:#fff; opacity: 1; transition: opacity 0.3s ease;}

* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
  font-family: 'helveticaneuecyrroman',sans-serif;
  @include desktop {
    font-size: 14px;
  }
}

body {
  
  //font-size: 16px;
  //line-height: 1.42;
  
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.wrapper {
  position: relative;
  min-height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.content{
  flex: 1 0 auto;
  background-color: #2f3239;
  &_withBg{
    background-color: #2D3F57;
  }
}
.content__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  height: 100%;
  padding: 0 5px;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  outline: none;
}
a{
  text-decoration: none;
  &:hover{
    cursor: pointer;
  }
}



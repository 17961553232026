.contacts-section{
  text-align: center;
  background: #2f3239 url("../img/decor/contacts-bg.png") no-repeat;
  padding-bottom: 40px;
  background-position: bottom right;
  background-size: 65%;
}

.contacts-section__content{
  display: flex;
  @include phone-hor-orientation{
    flex-direction: column-reverse;
    align-items: center;
  }
}
.contacts-section__left-col{
  width: 50%;
  @include phone-hor-orientation{
    width: 90%;
    height: 400px;
  }
}
.map{
  display: block;
  width: 100%;
  height: 100%;
}
.contacts-section__right-col{
  width: 50%;
  padding-left: 40px;
  @include phone-hor-orientation{
    width: 90%;
    padding-left: 0;
    margin-bottom: 20px;
  }
}
.contacts__list{

}
.contacts__item{
  text-align: left;
  margin-bottom: 20px;
  &:last-child{
    margin-bottom: 0;
  }
}
.contacts__title{
  font-size: 16px;
  color: #fff;
}
.contacts__text{
  color: #b5b3b3;
  font-size: 16px;
  font-family: 'helveticaneuecyrlight',sans-serif;
}
.comments-section{
  background: #2f3239 url("../img/decor/comments-bg.png") no-repeat;
  background-position: top center;
  padding: 10px 0 180px 0;
  position: relative;
  text-align: center;
  @include bigScreen{
    background-size: 30%;
  }
  @include desktop{
    background-size: 38%;
  }
  @include tablet{
    background-size: 65%;
  }
  @include phone-hor-orientation{
    background-size: 95%;
    padding: 10px 0 40px 0;
    background-position: center center;
  }
}
.comments-section__title{
  margin-bottom: 200px !important;
  display: inline-block;
  @include phone-hor-orientation{
    margin-bottom: 20px !important;
  }
}
.comment-item{

}
.comment-item__text{
  color: #fff;
  font-size: 16px;
  padding-bottom: 50px;
  text-align: left;
  width: 70%;
  margin: 0 auto;
}
.author__content{
  display: flex;
  color: #fff;
  align-items: center;
  width: 70%;
  margin: 0 auto;
}
.author__img{
  margin-right: 10px;
}
.author__name{
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: bold;
}
.comment-item__date{
  font-size: 12px;
}
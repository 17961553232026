.page-content{
  padding-top: 160px;
  @include tablet{
    padding-top: 160px;
  }
}
.service-page-top-section__bg-decor{
  position: absolute;
  width: 55%;
  height: 88%;
  right: 0;
  top: 70px;
  @include desktop{
    width: 58%;
  }
  @include tablet{
    width: 100%;
    height: 94%;
  }
}
.service-page-bottom-section__bg-decor{
  position: absolute;
  width: 80%;
  left: 0;
  top: 70px;
  height: 95%;
  @include desktop{
    width: 92%;
  }
  @include laptop{
    width: 100%;
    height: 94%;
  }
  @include tablet{
    width: 100%;
    height: 94%;
  }
  @include phone-hor-orientation{
    height: 96%;
  }
}
.service-page-top-section{
  position: relative;
  margin-bottom: 30px;
  @include tablet{
    margin-bottom: 60px;
  }
}
.servise-page__section-title{
  letter-spacing: 2px;
  color: #fff;
  font-size: 30px;

  margin-bottom: 40px;
  text-align: center;
  text-transform: uppercase;
  @include tablet{
    margin-bottom: 25px;
  }
}
.service-page-top-section__content{
  display: flex;
  @include tablet{
    flex-direction: column;
    align-items: center;
  }
}
.service-page-top-section__left-col{
  width: 40%;
  padding-top: 30px;
  padding-right: 20px;
  text-align: center;
  @include laptop{
    padding-right: 0;
    padding-left: 20px;
  }
  @include tablet{
    width: 90%;
    padding-right: 0;
    margin-bottom: 20px;
    padding-top: 25px;
  }
}
.service-page-top-section__title{
  font-size: 30px;
  color: #f6a961;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 30px;
  @include tablet{
    text-align: center;
  }
}
.service-page-top-section__text{
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
  @include tablet{
    margin-bottom: 20px;
  }
}
.service-page-top-section__right-col {
  width: 50%;
  padding: 10px 0 0 30px;
  display: flex;
  align-items: center;
  @include laptop{
    width: 60%;

  }
  @include tablet{
    width: 90%;
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.service-page-top-section__img{
  display: block;
  width: 70%;
}
.service-page-bottom-section{
  position: relative;
  margin-bottom: 60px;
  @include phone-hor-orientation{
    margin-bottom: 0;
  }
}
//#########################################| GALLERY |####################################################
.service-page-gallery{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.service-page-gallery__link{
  position: relative;
  margin: 20px;
  width: 29%;
  height: 230px;
  overflow: hidden;
  transition: 1s;
  &:hover{
    transform: scale(1.1);
    & .gallery-img__overlay{
      display: block;
    }
    & .gallery-img__icon{
      display: block;
    }
  }
  @include laptop{
    width: 40%;
  }
  @include phone-hor-orientation{
    width: 70%;
  }
}
.gallery-img__overlay{
  transition: .7s;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba($blue, .451);
  display: none;
}
.gallery-img__icon{
  @include centered(both);
  color: #fff;
  font-size: 35px;
  display: none;
}
.service-page-gallery__img{
  @include centered(both);
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.service-page-gallery__caption{
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-family: 'helveticaneuecyrlight', sans-serif;
  text-transform: uppercase;
}
//#########################################| SUB-SERVICEs LIST |#######################################

.sub-services__list{
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  @include tablet{
    justify-content: center;
  }
}
.sub-services__item{
  width: 22%;
  transition: .7s;
  margin: 15px;
  &:hover{
    transform: scale(1.1);
    box-shadow: 0 0 25px rgba(0,0,0,0.5);
  }
  @include tablet{
    width: 40% !important;
  }
}
.sub-services__link{
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  height: 150px;
  overflow: hidden;
}
.sub-services__overlay{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

}
.sub-services__img{
  @include centered(both);
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.sub-services__name{
  background-color: rgba(#c0661a, .651);
  padding: 5px 0;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.footer{
  height: 50px;
  background-color: #2e3a4b;
  color: #fff;
}
.footer__content{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  flex-wrap: wrap;
  @include phone-hor-orientation{
    font-size: 10px;
  }
}
.copyright {
  display: inline-block;
}
.copyright__link{
  color: #99b2cd;
}
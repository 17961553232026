.header{
  position: fixed;
  background: linear-gradient(to bottom, #0d1b4a, #0b1539);
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  height: 100px;
  z-index: 3000;
  transition: .7s;
  &.small{
    height: 80px;
    padding: 10px;
  }
  @include tablet{
    padding: 20px 5px;
  }
  @include tablet{
    padding: 0 5px;
  }
}
.header__content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-wrap: wrap;
}
.logo{
  display: block;
  width: 150px;
  @include phone-hor-orientation{
    padding-top: 0;
  }
}
.logo__img{
  display: block;
  width: 100%;
}
.nav{
  @include tablet{
    position: absolute;
    top: 100%;
    @include centered(x);
    width: 100%;
  }
}
.nav__list{
  display: flex;
  padding: 10px 0;
  @include tablet{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3000;
    display: none;
    text-align: center;
    background-color: #0d1949;
    padding: 20px;
  }
}
.nav__item{
  margin-right: 30px;
  @include laptop{
    margin-right: 15px;
  }
  @include tablet{
    margin-bottom: 20px;
    width: 100%;
    border-bottom: 1px dotted #fff ;
  }
}
.nav__link{
  color: #fff;
  position: relative;
  text-transform: uppercase;
  font-size: 14px;
  height: 100%;
  width: 100%;
  display: block;
  @include laptop{
    font-size: 12px;
  }
  @include tablet{
    font-size: 20px;
    padding: 10px 0;
  }
  &:hover{
    &:before{
      position: absolute;
      display: block;
      width: 50%;
      height: 2px;
      background-color: #dfa251;
      content: ' ';
      left: 0;
      top:-5px;
      @include tablet{
        display: none;
      }
    }
    &:after{
      position: absolute;
      display: block;
      width: 50%;
      height: 2px;
      background-color: #dfa251;
      content: ' ';
      right: 0;
      bottom: -5px;
      @include tablet{
        display: none;
      }
    }
  }
}

.phones{
  padding: 10px 0;
  @include tablet{
    display: none;
  }
}
.phones__item{
  margin-bottom: 10px;
  &:last-child{
    margin-bottom: 0;
  }
}
.phones__link{
  display: flex;
  align-items: center;
  color: #fff;
}
.phones__icon{
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15px;
  height: 15px;
}
.phones__number{

}
.hamburger{
  width: 40px;
  height: 40px;
  color:#fff;
  font-size: 30px;
  &:hover{
    cursor: pointer;
  }
  display: none;
  @include tablet{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
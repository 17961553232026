.about-section{
  background: #2f3239 url("../img/decor/about-bg.png") no-repeat;
  background-position: top center;
  padding: 10px 0 220px 0;
  position: relative;
  @include bigScreen{
    background-size: 30%;
  }
  @include desktop{
    background-size: 38%;
  }
  @include tablet{
    background-size: 65%;
  }
  @include phone-hor-orientation{
    background-size: 95%;
    background-position: center center;
    padding: 10px 0 20px 0;
  }
}
.about-section__right-decor{
  position: absolute;
  width: 50%;
  height: 75%;
  right: 0;
  top: -30px;
  @include phone-hor-orientation{
    display: none;
  }
}
.about-section__content{
  display: flex;
  @include phone-hor-orientation{
    flex-direction: column;
    align-items: center;
  }
}
.about-section__left-col{
  width: 50%;
  @include phone-hor-orientation{
    width: 95%;
    margin-bottom: 40px;
  }
}
.about-section__info{
  width: 80%;
  margin: 0 auto;
  direction: ltr;
  @include phone-hor-orientation{
    width: 95%;
  }
}
.about-section__title{
  color: #0065d7;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  direction: ltr;
  padding-left: 20px;
  text-transform: uppercase;
}
.about-section__text__wrapper{
  direction: rtl;
  height: 407px;
  overflow: auto;
  @include phone-hor-orientation{
    height: auto;
    overflow: visible;
  }
}
.about-section__text{
  color: #fff;
  font-size: 16px;
  padding-right: 20px;
  padding-left: 20px;
  direction: ltr;
  @include phone-hor-orientation{
    padding: 0;
  }
}
.about-section__right-col{
  width: 50%;
  padding-left: 30px;
  min-height: 480px;
  @include tablet{
    display: flex;
    flex-direction: column;
  }
  @include phone-hor-orientation{
    width: 95%;
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.about-slider{
  width: 80%;
  position: relative;
  top: -30px;
  @include tablet{
    width: 95%;
  }
}
.about-slider__item{

}
.about__img{
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.about-slider__title{
  color: #fff;
  font-size: 20px;
  padding-left: 3px;
}
//##################################| SCROLL CONTROLL STYLE |#################################
::-webkit-scrollbar-button {
  background-image:url('');
  background-repeat:no-repeat;
  width:5px;
  height:0px
}

::-webkit-scrollbar-track {
  background-color:#fff;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 0px;
  border-radius: 0px;
  background-color:#0065d7;
}

::-webkit-scrollbar-thumb:hover{
  background-color:#56999f;
}

::-webkit-resizer{
  background-repeat:no-repeat;
  width:1px;
  height:0px
}

::-webkit-scrollbar{
  width: 1px;
}
//##################################| SCROLL CONTROLL STYLE  END|#################################

//#########################################| SUB-SERVICEs LIST |#######################################
.sub-service-page__bg-decor{
  position: absolute;
  @include centered(both);
  width: 90%;
  height: 200%;
  top: 130px;
}
.sub-services__list{
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
}
.sub-services__item{
  width: 22%;
  transition: .7s;
  margin: 15px;
  &:hover{
    transform: scale(1.1);
    box-shadow: 0 0 25px rgba(0,0,0,0.5);
  }
}
.sub-services__link{
  position: relative;
  display: block;
  width: 100%;
  color: #fff;
  height: 150px;
  overflow: hidden;

}
.sub-services__overlay{
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

}
.sub-services__img{
  display: block;
  width: 100%;
  margin-bottom: 20px;
}
.sub-services__name{
  background-color: rgba(#c0661a, .651);
  padding: 5px 0;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  width: 100%;
}
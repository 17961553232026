@font-face {
  font-family: 'helveticaneuecyrlight';
  src: url('../fonts/helveticaneuecyr-light-webfont.woff2') format('woff2'),
  url('../fonts/helveticaneuecyr-light-webfont.woff') format('woff'),
  url('../fonts/helveticaneuecyr-light.otf') format('otf');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'helveticaneuecyrroman';
  src: url('../fonts/helveticaneuecyr-roman-webfont.woff2') format('woff2'),
  url('../fonts/helveticaneuecyr-roman-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}


@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.woff') format('woff');

  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-numbers4:before {
  content: "\e900";
}
.icon-numbers1:before {
  content: "\e901";
}
.icon-numbers2:before {
  content: "\e902";
}
.icon-numbers3:before {
  content: "\e903";
}


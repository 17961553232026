.order-form__wrapper{
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3000;
  background-color: rgba($grey,.7);
}
.close-btn{
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 23px;
  &:hover{
    color: $blue;
  }
}
.order-form__title{
  margin-bottom: 25px;
  font-size: 20px;
  text-align: center;
  color: $blue;
  border-bottom: 1px solid $grey;
  padding-bottom: 5px;
  @include phone{
    font-size: 16px;
    margin-bottom: 15px;
  }
}

.order-form{
  display: none;
  position: relative;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  width: 450px;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 14px;
  text-align: center;
  background-color: #fff;
  @include tablet{
    padding: 20px;
  }
  @include phone-hor-orientation{
    width: 90%;
  }
  @include phone{
    width: 90%;
  }
}
.form__info-caption{
  @include centered(both);
  height: 100px;
  width: 200px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px;
  display: none;
  &.sucsess{
    background-color: forestgreen;
    display: flex;
  }
  &.error{
    background-color: red;
    display: flex;
  }
}

.input-text__wrapper{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.input-text{

}
.input-text_long{
  width: 100%;
}



.message-box{
  height: 80px;
  width: 100%;
  @include phone{
    height: 50px;
  }
}

.order-btn_wrapper{
  text-align: right;
  margin-bottom: 0;
}
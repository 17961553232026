.portfolio-section{
  min-height: 736px;
  padding: 20px 5px 120px 5px;
  position: relative;
  text-align: center;
  background: #2f3239 url("../img/decor/portfolio-bg.png") no-repeat;
  background-position: top center;
  @include bigScreen{
    background-size: 30%;
  }
  @include desktop{
    background-size: 38%;
  }
  @include tablet{
    background-size: 65%;
    padding: 20px 5px 180px 5px;
  }
  @include phone-hor-orientation{
    background-size: 95%;
    background-position: center center;
    padding: 20px 5px 60px 5px;
  }
}
.portfolio-section__sub-title{
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: normal;
  font-family: 'helveticaneuecyrlight',sans-serif;
}

.portfolio-preview__list{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  @include phone-hor-orientation{
    align-items: center;
    justify-content: center;
  }
}
.portfoli-preview__item{
  margin-bottom: 30px;
  width: 31%;
  transition: all 200ms ease-in;
  transform: scale(1);
  &:hover{
    transform: scale(1.1);
    box-shadow: 0 0 35px rgba(0,0,0,0.5);
  }
  @include phone-hor-orientation{
    width: 95%;
  }
}
.portfoli-preview__img{
  display: block;
  width: 100%;
}
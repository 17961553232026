.partners-section{
  text-align: center;
  position: relative;
  background-color: #2f3239;
  padding-bottom: 40px;
}
.partners-slider{
  background-color: #404249;
  padding: 20px 30px;
  width: 100%;
  margin: 0 auto;
}
.partner__item{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px solid #a98b2f;
  margin-right: 25px;
  height: 150px;
  width: 150px;
  background-color: #fff;
  &:last-child{
    margin-right: 0;
  }
}
.partner__img{
  display: block;
  width: 100%;
}
.button{
  display: inline-block;
  padding: 10px 30px;
  border:1px solid #fff;
  font-size: 15px;
  font-family: helveticaneuecyrroman,sans-serif;
  background-color: #dfa251;
  color: #fff;
  font-size: 14px;
  border-radius: 3px;
  &:hover{
    color: #dfa251;
    background: #fff;
    border: 1px solid #dfa251;
    cursor: pointer;
  }
  &_dark-border{
    border:1px solid #000;
  }
  &_white{
    background-color: #fff;
    color: #dfa251;
  }
  &_round{
    background: transparent;
    border-radius: 15px;
    border: 1px solid $blue;
    padding: 5px 20px;
    text-transform: uppercase;
  }
  &_form{
    border-radius: 15px;
    border: 1px solid transparent;
    padding: 5px 20px;
    text-transform: uppercase;
    background-color: $blue;
    color: #fff;
    &:hover{
      background-color: transparent;
      color: $blue;
      border: 1px solid $blue;
    }
  }
}
.section-title{
  color: #fff;
  text-align: center;
  display: inline-block;
  font-size: 36px;
  position: relative;
  text-transform: uppercase;
  font-family: 'helveticaneuecyrlight',sans-serif;
  font-weight: 100;
  letter-spacing: 5px;
  margin-bottom: 30px;
  padding-left: 30px;
  &:before{
    display: block;
    width: 8px;
    height: 65%;
    position: absolute;
    left: 0;
    background-color: #478cda;
    content:  ' ';
    @include centered(y);
  }
  @include phone-hor-orientation{
    font-size: 25px;
  }
}
//##############################################| TABS |################################
.tabs-controls__list{
  display: flex;
  justify-content: center;
  padding: 30px 0;
  @include phone-hor-orientation{
    align-items: center;
  }
}
.tabs-controls__item{
  margin-right: 40px;
  text-align: center;
  &:last-child{
    margin-right: 0;
  }
  @include phone-hor-orientation{
    margin-right: 10px;
  }
}
.tabs-controls__link{
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  &.active{
    color: $blue;
  }
  @include phone-hor-orientation{
    font-size: 12px;
  }
  @include phone{
    font-size: 10px;
  }
}
.services-section{
  padding: 50px 0 40px 0;
  background: url("../img/decor/services-bg.jpg") no-repeat;
  background-size: cover;
  text-align: center;
  @include phone-hor-orientation{
    padding: 20px 0 40px 0;
  }
}
.services__list{

}
.services__item{
  position: relative;
  color: #fff;
  width: 100%;
  margin: 0 auto;
  transition: all 200ms ease-in;
  transform: scale(1);
  z-index: 100;
  &:hover{
    transform: scale(1.1);
    box-shadow: 0 0 35px rgba(0,0,0,0.5);
    z-index: 200;
  }
}
.services__item1{
  background: url("../img/userfiles/services1.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
}
.services__item2{
  background: url("../img/userfiles/services2.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
}
.services__item3{
  background: url("../img/userfiles/services3.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
}
.services__item4{
  background: url("../img/userfiles/services4.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
}
.services__item5{
  background: url("../img/userfiles/services5.jpg") no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 60px 0;
}
.services__link{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;
}
.service__content{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //@include centered(both);
}
.service__title{
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.service__disc{
  text-align: center;
  padding-bottom: 20px;
  width: 50%;
  font-size: 14px;
  @include phone-hor-orientation{
    width: 90%;
  }
}
.service__btn{
  //@include centered(x);
  //bottom: -20px;
  z-index: 3000;
  box-shadow: 0 0 15px rgba(0,0,0,0.5);
  border: 1px solid #000;
}